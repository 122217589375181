<i18n lang="json">
{
	"ru": {
    "toFavorite": "В избранное",
    "save": "Сохранено"
	},
	"en": {
    "toFavorite": "To favorite",
    "save": "Save"
	}
}
</i18n>
<template>
  <div class="favorite" @click="toFavorite">
    <span class="icon-app-heart-tile-fill" v-if="favorite"></span>
    <span class="icon-app-heart-tile" v-else></span>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { Objects } from "@/components/api/";
import sutochnoMetrika from "@/utils/sutochnoMetrika";

export default {
  name: "Favorite",
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      favorite: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setFavorite();
    });
  },
  methods: {
    ...mapActions("user", ["loadUserData"]),
    ...mapMutations("user", ["toggleFavoritesForGuest"]),
    setFavorite(){
      if(!this.isAuth){
        this.favoritesForGuest.forEach(element => {
          if (element == this.id) {
            this.favorite = true;
          }
        });
        return;
      }

      if (
        this.userData &&
        this.userData.objects &&
        this.userData.objects.favorites_ids
      ) {
        this.userData.objects.favorites_ids.filter((id) => {
          if (id == this.id) {
            this.favorite = true;
          }
        });
      }
    },
    async toFavorite() {
      if(!this.favorite)
        sendEventGtag("object_sa_m", {
          click: "add2favorite",
        });
      this.favorite = !this.favorite;

      Objects.toggleFavorite(Number(this.id)).then((res) => {
        this.favorite = res.data.data.is_favorite;
        if (!this.isAuth) {
          this.toggleFavoritesForGuest({
            id: String(this.id),
            value: this.favorite,
          });
        }
        setTimeout(() => {
          this.loadUserData({ useCache: true });
        }, 0);
      });

      let screenBlock = this.favorite ? 'object_favorite' : 'object_unfavorite'
      sutochnoMetrika.detail("click", screenBlock, {
        ...this.commonParamsForMetrika,
        page: "object",
      })
    },
  },
  computed: {
    ...mapState("user", ["userData", "favoritesForGuest", "isAuth"]),
    ...mapGetters("search", ["commonParamsForMetrika"]),
  },
};
</script>

<style lang="scss" scoped>
.favorite {
  // .icon-app-heart,
  // .icon-app-heart-full {
  //   padding-right: 10px;
  // }
  .icon-app-heart-tile,
  .icon-app-heart-tile-fill {
    font-size: 16px;
  }
  .icon-app-heart-tile-fill {
    color: #f51449;
  }
}
</style>
