<i18n lang="json">
{
	"ru": {
    "copyLink": "Копировать ссылку",
    "linkCopied": "Ссылка скопирована",
    "copyText":{
      "0": "Отличное жилье на",
      "1": "в городе",
      "2": "Забронируй прямо сейчас на Суточно.ру"
    }
	},
	"en": {
    "copyLink": "Copy link",
    "linkCopied": "Link copied",
    "copy Text":{
      "0": "Excellent accommodation for", 
      "1": "in the city", 
      "2": "Book now on Sutochno.ru"
    }
	}
}
</i18n>
<template>
  <div v-click-outside="clickOutside" class="share" @click.self="toggle()">
    <div class="share__content" @click="toggle()">
      <span class="icon-app-share-simple"></span>
    </div>
    <div v-if="view" class="share__list">
      <div class="share__item" @click="copyLink()">
        <div class="share__icon">
          <img src="~/assets/img/ico_copy.svg" />
        </div>
        <div class="share__value">
          <span v-if="isCopied">{{ t("linkCopied") }}</span>
          <span v-else>{{ t("copyLink") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { useI18n } from 'vue-i18n';
import urlFilteredQuery from "@/utils/urlFilteredQuery";

export default {
  name: "Share",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      view: false,
      isCopied: false,
    };
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
    locationObject() {
      return this.getObjectData?.location;
    },
    copyText() {
      return `${this.$i18n.t("copyText.0")} ${
        this.locationObject?.address
      } ${this.$i18n.t("copyText.1")} ${
        this.locationObject?.location
      }! ${this.$i18n.t("copyText.2")}`;
    },
  },
  methods: {
    toggle() {
      // Проверка поддержки navigator.share
      if (navigator.share) {
        // navigator.share принимает объект с URL, title или text
        navigator.share({
          // title: "можно указать заголовок",
          text: this.copyText,
          url: window.location.href,
        });
        sendEventGtag("object_sa_m", {
          click: "copy_link",
        });
      } else if (this.view) {
        this.close();
      } else {
        this.show();
      }
    },
    close() {
      this.view = false;
      this.isCopied = false;
      this.$emit("close");
    },
    show() {
      this.view = true;
      this.$emit("show");
      sendEventGtag("object_sa_m", {
        click: "copy_link",
      });
    },
    copyLink() {
      const copyText = document.createElement("input");
      copyText.value = urlFilteredQuery(["occupied", "guests_adults", "guests_childrens"]);
      document.body.appendChild(copyText);
      copyText.select();
      document.execCommand("copy");
      this.isCopied = true;
    },
    clickOutside() {
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.share {
  position: relative;
  &__content {
    display: flex;
  }
  &__list {
    position: absolute;
    top: calc(100% + 4px);
    // left: 0;
    right: 0;
    z-index: 1;
    color: #000;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    background: #fff;
    border: 1px solid #d8d8d8;
    width: 235px;
    max-height: 320px;
    overflow-y: auto;
    padding: 20px;
  }
  .icon-app-share-simple {
    font-size: 16px;
  }
  &__item {
    display: flex;
  }
  &__icon {
    min-width: 28px;
    height: 28px;
  }
  &__value {
    min-width: calc(100% - 28px);
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
  &__data {
    width: 10px;
  }
}
</style>
