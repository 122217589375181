<template>
  <div class="options">
    <!-- <Share
      class="item item_share"
      :class="{ show: showShares }"
      @show="showShares = true"
      @close="showShares = false"
    /> -->
    <Favorite v-if="!whitelabel" :id="Number(id)" class="item item_favorite" />
  </div>
</template>

<script>
import Favorite from './Favorite'
import Share from './Share'
import { mapState } from "vuex";

export default {
  name: "Options",
  props: {
    id: {
      type: String
    }
  },
  components: {
    Favorite,
    Share
  },
  data() {
    return {
      showShares: false
    }
  },
  computed: {
    ...mapState("user", ["whitelabel"]),
  },
};
</script>

<style lang="scss" scoped>
.options {
  display: flex;
  height: 100%;

  .item {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    transition: width 0.3s;
    padding: 0 10px;

    &_favorite {
      :deep(.options__text) {
        padding-top: 2px;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
